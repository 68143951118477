
import DOMUtil from 'zunzun/flyutil/dom.mjs'

import Animation from 'zunzun/animator/event/chain.mjs'

const DELAY = 150;

window.addEventListener("load", async (evt) => {
  try {
    hide_loading_overlay();


    const bulbs_svg = document.body.querySelector("object.server-bulbs");
    animate_bulbs(bulbs_svg, "path38-0-9-9-9-4-6", "path38-0-4-4-8-5", "path38-0-99-9-7-31-6", "path38-2-8-1-2");


    const event_chain = [];
    const prices_and_setup = document.querySelector("div.prices-and-setup div.setup-wrap");

    const select_buttons = prices_and_setup.querySelectorAll("a.select");
    for (let btn of select_buttons) {
      event_chain.push({
        delay: DELAY,
        element: btn,
        class: "display"
      });
    }

    const setup_button = document.getElementById("setup-button");
    event_chain.push({
      delay: DELAY,
      element: setup_button,
      class: "display"
    });

    const setup_button_animation = new Animation(event_chain);

    const contact_encouragement = document.body.querySelector("a.contact-encouragement");
    const img_robot = contact_encouragement.querySelector("div.img.robot");
    const img_bubble_small = contact_encouragement.querySelector("div.img.bubble-small");
    const img_bubble_big = contact_encouragement.querySelector("div.img.bubble-big");
    const img_bubble_text = contact_encouragement.querySelector("div.img.bubble-text");
    const paragraph = contact_encouragement.querySelector("p");
    const contact_div = contact_encouragement.querySelector("div.contact");


    const contact_animation = new Animation([
      {
        delay: 0,
        element: contact_encouragement,
        class: "display"
      },
      {
        delay: DELAY,
        element: img_robot,
        class: "display"
      },
      {
        delay: DELAY,
        element: img_bubble_small,
        class: "display"
      },
      {
        delay: DELAY,
        element: img_bubble_big,
        class: "display"
      },
      {
        delay: DELAY,
        element: img_bubble_text,
        class: "display"
      },
      {
        delay: DELAY,
        element: paragraph,
        class: "display"
      },
      {
        delay: DELAY,
        element: contact_div,
        class: "display"
      }
    ]);


    const ck_scroll = (evt) => {
      if (DOMUtil.intersects_viewport(prices_and_setup)) {
        setup_button_animation.play();
      } else {
        setup_button_animation.stop();
      }

      if (DOMUtil.intersects_viewport(contact_encouragement)) {
        contact_animation.play();
      }  else {
        contact_animation.stop();
      }
    }
    document.addEventListener('scroll', ck_scroll);
    ck_scroll();

  } catch (e) {
    console.error(e);
  }
});

