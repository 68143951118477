
export default class EventChainAnimation {
  constructor(events) {
    this.events = events;
    this.started = false;
  }

  play() {
    if (!this.started) {
      this.started = true;
      let total_delay = 0;
      for (let anim of this.events) {
        if (
          anim.element.classList.contains(anim.class)
        ) {
          anim.element.classList.remove(anim.class);
        }

        if (!anim.delay) anim.delay = 0;
        total_delay += anim.delay;

        if (anim.timeout_id) clearTimeout(anim.timeout_id);
        anim.timeout_id = setTimeout(() => {
          anim.timeout_id = undefined;
          if (!anim.element.classList.contains(anim.class)) {
            anim.element.classList.add(anim.class);
          }
        }, total_delay);
      }
    }
  }

  stop() {
    if (this.started) {
      this.started = false;
      for (let anim of this.events) {
        if (anim.timeout_id) {
          clearTimeout(anim.timeout_id);
          anim.timeout_id = undefined;
        }
        if (anim.element.classList.contains(anim.class)) {
          anim.element.classList.remove(anim.class);
        }
      }
    }
  }
}
